<template>
  <div class="filter-by-status">
    <div class="begin">
      <div class="label mb-2">{{ $t('status') + 's' }}</div>
      <div class="input-bloc">
        <Multiselect
          v-model="category"
          :options="options"
          :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded-lg px-4 py-5 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
          @select="selectCategory"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
export default {
  name: 'filter-by-status',
  components: { Multiselect },
  data () {
    return {
      category: '',
      options: [
        { value: 'inService', label: 'En service' },
        { value: 'suspended', label: 'Suspendus' },
        { value: 'onVacation', label: 'En congé' },
        { value: 'reserved', label: 'Reservé' },
        { value: 'archived', label: 'Archivé' }
      ]
    }
  },
  methods: {
    selectCategory () {
      // console.log(this.category)
      this.$emit('selectStatus', this.category)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .ipt {
    background: $team_gray_popup;
  }
</style>
