<template>
  <div class="instruction-details">
    <popup-base>
      <div class="begin bg-white w-3/4 m-auto border">
        <div class="header p-8 flex justify-between">
          <div class="title-bloc">
            <div class="title text-2xl font-medium">{{ $t('instructionDetails') }}</div>
          </div>
          <div class="close cursor-pointer" @click="closeAdd">
            <icon :data="icons.close" original width="25" height="25"/>
          </div>
        </div>
        <div class="body p-8">
          <div class="instructions flex items-center justify-between">
            <div class="instruction-title font-medium text-xl">{{ detailsInstruction.instruction.title }}</div>
            <div class="see flex items-center">
              {{ getAllNumberSee }}
              <div class="see-number">{{ detailsInstruction.numberSee }}</div>
              <div class="attach-icon ml-2">
                <icon :data="icons.eye" original width="15" height="15"/>
              </div>
            </div>
          </div>
          <div class="description mt-4">{{ detailsInstruction.instruction.description }}</div>
          <div class="finish flex mt-4">
            <div class="part-1 w-1/2">
              <div class="state">
                <span class="state-title">{{ $t('date') + ' : ' }}</span>
                <span class="status pl-4 capitalize">{{ new Date(detailsInstruction.sendAt).toLocaleDateString('FR', options) }}</span>
              </div>
              <div class="state mt-4">
                <span class="state-title">{{ $t('joinPieces') + ' : ' }}</span>
                <span v-if="detailsInstruction.instruction.file !== ''" class="status pl-4 cursor-pointer" @click="openInstructionLink">{{ 'consigne.pdf' }}</span>
                <span v-else class="status pl-4 cursor-pointer">{{ '----' }}</span>
              </div>
            </div>
            <div class="part-2 w-1/2"></div>
          </div>
          <div class="beneficiary mt-4">
            <div class="state-title mb-4">{{ $t('agentsMenu') + ' : ' }}</div>
            <div v-for="(item, i) in allAgents" :key="i" class="response mb-4 flex items-center">
              <div
                v-if="item.profilePicture !== ''"
                class="picture rounded-full w-10 h-10 mr-2 bg-no-repeat bg-cover"
                v-bind:style="{ backgroundImage: 'url(' + item.profilePicture + ')' }"
              ></div>
              <div
                v-else
                :style="backgroundDefine"
                class="pictures w-12 h-12 text-center bg-gray-500 pt-4 ml-4 mr-2 rounded-full text-sm font-bold text-white"
              >
                {{ getFirstLetter(item.name) }}
              </div>
              <div class="names ml-2">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '../add/popup-base'
import close from '../../../assets/icons/ic_close.svg'
import eye from '../../../assets/icons/ic_eye.svg'
import { collection, query, where, getDocs, orderBy, onSnapshot } from 'firebase/firestore'
import { firestore } from '@/main'
export default {
  name: 'instruction-details',
  components: { PopupBase },
  props: {
    detailsInstruction: Object
  },
  data () {
    return {
      icons: { close, eye },
      options: { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' },
      allAgents: [],
      backgroundDefine: {
        backgroundColor: '#000000'
      }
    }
  },
  created () {
    console.log(this.detailsInstruction)
    this.getAllAgents()

    const colors = ['#01C8C8', '#FFA83D', '#6D1FEB', '#2F80ED', '#0DCE89', '#7D47B3', '#1ECC78', '#C1CD18', '#581845']
    const value = Math.floor(Math.random() * (colors.length + 1))
    if (value < colors.length) {
      this.backgroundDefine.backgroundColor = colors[value]
    } else {
      this.backgroundDefine.backgroundColor = colors[0]
    }
  },
  computed: {
    getAllNumberSee () {
      let number = 0
      const cities = []
      for (const i in this.allAgents) {
        const q = query(collection(firestore, 'notifications', this.allAgents[i].uid, 'notifications'),
          where('isRead', '==', true),
          orderBy('createdAt', 'desc'))
        onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log(doc.data())
            cities.push(doc.data())
            // this.allNotification = cities
          })
        })
        for (const i in cities) {
          if (cities[i].uid === this.detailsInstruction.uid && cities[i].isRead === true) {
            console.log('citeis', cities[i])
            number = number + 1
            console.log(number)
          }
        }
      }
      return number
    }
  },
  methods: {
    async getAllAgents () {
      if (this.detailsInstruction.receiver.agents.length > 0) {
        for (const i in this.detailsInstruction.receiver.agents) {
          const q = query(collection(firestore, 'agents'),
            where('uid', '==', this.detailsInstruction.receiver.agents[i]))

          const querySnapshot = await getDocs(q)
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, ' => ', doc.data())
            this.allAgents.push(doc.data())
          })
        }
      }

      if (this.detailsInstruction.receiver.controllers.length > 0) {
        for (const i in this.detailsInstruction.receiver.controllers) {
          const q = query(collection(firestore, 'agents'),
            where('uid', '==', this.detailsInstruction.receiver.controllers[i]))

          const querySnapshot = await getDocs(q)
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, ' => ', doc.data())
            this.allAgents.push(doc.data())
          })
        }
      }
    },
    getFirstLetter (fullname) {
      const nameTable = fullname.split(' ')
      if (nameTable.length >= 2) {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[1].charAt(0).toUpperCase()
      } else {
        return nameTable[0].charAt(0).toUpperCase() + nameTable[0].charAt(1).toUpperCase()
      }
    },
    openInstructionLink () {
      window.open(this.detailsInstruction.instruction.file, '_blank')
    },
    closeAdd () {
      this.$emit('closeInstructionDetails', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .begin {
    border-top: 4px solid $team_blue;
  }
  .header {
    background: $team_gray_popup;
  }
  .state-title {
    color: #979797;
  }
  .beneficiary {
    max-height: 22rem;
    overflow-y: scroll;
  }
</style>
