<template>
  <div class="instructions">
    <div class="begin pb-12">
      <div class="header">
        <!-- HEADER POPUP -->
        <the-header
          :show-file-button="false"
          :show-number="false"
          :add-button="$t('newInstructionButton')"
          @searchForItems="searchForItems"
          @clickBlueButton="clickBlueButton"
        />
        <!-- HEADER POPUP END -->
      </div>
      <div class="body">
        <div class="start bg-white rounded-lg">
          <div class="start-header py-6 px-8 flex items-center">
            <div
              class="part-1 text-xl mr-6 cursor-pointer"
              :class="{ 'selected_position': position === 1 }"
              @click="changePosition(1)"
            >
              {{ $t('sent') }}
            </div>
            <div
              class="part-1 text-xl ml-6 cursor-pointer"
              :class="{ 'selected_position': position === 2 }"
              @click="changePosition(2)"
            >
              {{ $t('programed') }}
            </div>
          </div>
          <div class="start-body p-8">
            <div v-if="position === 1">
              <div class="loading" :class="{ 'not_empty': loading === false }">
                <loading
                  :active="loading"
                  :can-cancel="false"
                  :on-cancel="onCancel"
                  :is-full-page="fullPage"
                  background-color="#FFFFFF"
                  color="#324971"
                  loader="dots"
                ></loading>
              </div>
              <div
                v-if="nothing && !loading"
                class="no-student text-center text-xl text-gray-500 py-32"
              >
                {{ $t('noNewInstructionAdded') }}
              </div>
              <div v-if="!loading"></div>
              <instruction-lists
                v-for="(item, index) in sortedArrayInstruction"
                :key="index"
                :instructions-lists="item"
                @click="openInstructionDetails(index)"
              />
            </div>
            <div v-if="position === 2">
              <div class="loading" :class="{ 'not_empty': loading === false }">
                <loading
                  :active="loading"
                  :can-cancel="false"
                  :on-cancel="onCancel"
                  :is-full-page="fullPage"
                  background-color="#FFFFFF"
                  color="#324971"
                  loader="dots"
                ></loading>
              </div>
              <div
                v-if="nothingProgramed && !loading"
                class="no-student text-center text-xl text-gray-500 py-32"
              >
                {{ $t('noNewProgramedInstruction') }}
              </div>
              <div v-if="!loading">
                <instruction-lists
                  v-for="(item, index) in sortedArrayInstructionProgrammed"
                  :key="index"
                  :instructions-lists="item"
                  @click="openProgramedInstructionDetails(index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ADD NEW INSTRUCTION POPUP -->
    <new-instruction
      v-if="isNewInstruction"
      @closeNewOne="closeNewOne"
      @openConsignee="openConsignee"
      @getInstructionInformation="getInstructionInformation"
    />
    <!-- ADD NEW INSTRUCTION CONSIGNEE POPUP -->
    <new-instruction-consignee
      v-if="isInstructionConsignee"
      @goBackNewOne="goBackNewOne"
      @openTime="openTime"
      @instructionReceiver="instructionReceiver"
    />
    <!-- ADD NEW INSTRUCTION TIME POPUP -->
    <new-instruction-time
      v-if="isInstructionTime"
      :instruction-information="newInstructionInformation"
      :instruction-receiver="newInstructionReceiver"
      @closeTime="closeTime"
      @goBackConsignee="goBackConsignee"
    />
    <!-- SUCCESS ADD POPUP -->
    <success-popup-base
      v-if="isSuccess"
      :success-description="$t('successAddInstruction')"
      @closeSuccess="closeSuccess"
    />
    <!-- INSTRUCTION DETAILS POPUP -->
    <instruction-details
      v-if="isInstructionDetails"
      :details-instruction="instructionsDetails"
      @closeInstructionDetails="closeInstructionDetails"
    />
  </div>
</template>

<script>
import TheHeader from '../../components/the-header'
import InstructionLists from '../../components/helper/instructions/instruction-lists'
import NewInstruction from '../../components/helper/instructions/new-instruction'
import NewInstructionConsignee from '../../components/helper/instructions/new-instruction-consignee'
import NewInstructionTime from '../../components/helper/instructions/new-instruction-time'
import Loading from 'vue3-loading-overlay'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css'
import { collection, query, orderBy, where, onSnapshot } from 'firebase/firestore'
import { firestore } from '../../main'
import SuccessPopupBase from '../../components/helper/add/success-popup-base'
import InstructionDetails from '../../components/helper/instructions/instruction-details'
export default {
  name: 'index',
  components: { InstructionDetails, SuccessPopupBase, Loading, NewInstructionTime, NewInstructionConsignee, NewInstruction, InstructionLists, TheHeader },
  data () {
    return {
      position: 1,
      allInstructions: [],
      allInstructionsProgrammed: [],
      isNewInstruction: false,
      isInstructionConsignee: false,
      isInstructionTime: false,
      newInstructionInformation: {},
      newInstructionReceiver: {},
      loading: true,
      loadingProgramed: true,
      fullPage: false,
      nothing: false,
      nothingProgramed: false,
      isSuccess: false,
      isInstructionDetails: false,
      instructionsDetails: {},
      searching: ''
    }
  },
  created () {
    this.getAllInstruction()
    this.getAllInstructionProgramed()
    if (this.allInstructions.length === 0) {
      this.loading = false
      this.nothing = true
    } else {
      this.loading = false
      this.nothing = false
    }

    if (this.allInstructionsProgrammed.length === 0) {
      this.loading = false
      this.nothingProgramed = true
    } else {
      this.loading = false
      this.nothingProgramed = false
    }
  },
  computed: {
    sortedArrayInstruction: function () {
      let sorted = this.allInstructions
      if (this.searching !== '') {
        let authorNameSearchString = this.searching
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if ((item.instruction.title !== null && (item.instruction.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1)) {
            // console.log(item)
            return item
          }
        })
      }
      return sorted
    },
    sortedArrayInstructionProgrammed: function () {
      let sorted = this.allInstructionsProgrammed
      if (this.searching !== '') {
        let authorNameSearchString = this.searching
        authorNameSearchString = authorNameSearchString.toLowerCase()
        sorted = sorted.filter(function (item) {
          if ((item.instruction.title !== null && (item.instruction.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(authorNameSearchString.normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1)) {
            // console.log(item)
            return item
          }
        })
      }
      return sorted
    }
  },
  methods: {
    async getAllInstruction () { // GET ALL INSTRUCTIONS
      const q = query(collection(firestore, 'instruction'),
        where('sendAt', '<=', new Date().getTime()),
        orderBy('sendAt', 'desc'),
        orderBy('createdAt', 'desc'))
      onSnapshot(q, (querySnapshot) => {
        const cities = []
        querySnapshot.forEach((doc) => {
          // console.log(doc.data())
          cities.push(doc.data())
          this.allInstructions = cities
          if (this.allInstructions.length === 0) {
            this.loading = false
            this.nothing = true
          } else {
            this.loading = false
            this.nothing = false
          }
        })
      })
    },
    async getAllInstructionProgramed () { // GET ALL PROGRAMED INSTRUCTIONS
      const q = query(collection(firestore, 'instruction'),
        where('sendAt', '>', new Date().getTime()),
        orderBy('sendAt'),
        orderBy('createdAt', 'desc'))
      onSnapshot(q, (querySnapshot) => {
        const cities = []
        querySnapshot.forEach((doc) => {
          // console.log(doc.data())
          cities.push(doc.data())
          this.allInstructionsProgrammed = cities
          if (this.allInstructionsProgrammed.length === 0) {
            this.loading = false
            this.nothingProgramed = true
          } else {
            this.loading = false
            this.nothingProgramed = false
          }
        })
      })
    },
    searchForItems (val) { // GET INPUT SEARCH
      this.searching = val
    },
    closeInstructionDetails (val) { // CLOSE INSTRUCTION DETAILS POPUP
      this.isInstructionDetails = val
    },
    openInstructionDetails (index) { // OPEN INSTRUCTION DETAILS POPUP
      this.isInstructionDetails = true
      this.instructionsDetails = this.sortedArrayInstruction[index]
    },
    openProgramedInstructionDetails (index) { // OPEN PROGRAMED INSTRUCTION DETAILS POPUP
      this.isInstructionDetails = true
      this.instructionsDetails = this.sortedArrayInstructionProgrammed[index]
    },
    closeSuccess (val) {
      this.isSuccess = val
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    instructionReceiver (val) { // GET THE NEW INSTRUCTION RECEIVER
      this.newInstructionReceiver = val
    },
    getInstructionInformation (val) { // GET THE NEW INSTRUCTION INFORMATION
      this.newInstructionInformation = val
    },
    goBackConsignee (val) { // GO BACK TO NEW INSTRUCTION CONSIGNEE POPUP
      this.closeTime(false)
      this.isInstructionConsignee = val
    },
    closeTime (val) { // CLOSE NEW INSTRUCTION TIME POPUP
      this.isInstructionTime = val
      this.isSuccess = true
    },
    openTime (val) { // OPEN NEW INSTRUCTION TIME POPUP
      this.isInstructionTime = val
      this.isInstructionConsignee = false
    },
    goBackNewOne (val) { // GO BACK TO NEW INSTRUCTION POPUP
      this.isInstructionConsignee = val
      this.isNewInstruction = true
    },
    openConsignee (val) { // OPEN NEW INSTRUCTION CONSIGNEE POPUP
      this.closeNewOne(false)
      this.isInstructionConsignee = val
    },
    closeNewOne (val) { // CLOSE NEW INSTRUCTION POPUP
      this.isNewInstruction = val
    },
    clickBlueButton (val) { // OPEN NES INSTRUCTION POPUP
      this.isNewInstruction = val
    },
    changePosition (index) { // CHANGE POSITION TITLE
      this.position = index
    },
    onCancel () {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .start {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  .start-header {
    border-bottom: 2px solid #BABFC5;
  }
  .part-1 {
    color: $team_gray_percent;
  }
  .selected_position {
    color: $team_blue;
    font-weight: 600;
  }
  .loading {
    position: relative;
    height: 10vh;
  }
  .not_empty {
    height: auto;
  }
</style>
