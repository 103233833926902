<template>
  <div class="filter-by-zone">
    <div class="begin relative">
      <div class="flex w-full relative" @click="openList">
        <div class="ipt relative justify-center items-center px-4 py-4 w-full cursor-pointer border rounded-lg">
          <div class="current-month-text mr-4">
            {{ status }}
          </div>
          <div class="town-select pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
            <icon :data="icons.dropdown" original width="25" height="25" />
          </div>
        </div>
      </div>
      <div
        v-if="wantToSelect"
        class="absolute select-list bg-white border z-50 w-full overflow-y-scroll max-h-44"
      >
        <div
          v-for="(item, key) in statusList"
          :key="key"
          class="listes flex px-4 py-3 cursor-pointer w-full"
          @click="itemIsSelected(item)"
        >
          <div class="action flex items-center w-full">
            <div class="name">
              <div class="title text-black">{{ item }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dropdown from '../../../assets/icons/ic_drop_down.svg'
export default {
  name: 'filter-by-zone',
  props: {
    statusList: {
      type: Array,
      default: function () {
        return [
          {
            name: 'value'
          }
        ]
      }
    }
  },
  data () {
    return {
      icons: { dropdown },
      status: '',
      wantToSelect: false
    }
  },
  methods: {
    openList () {
      this.wantToSelect = !this.wantToSelect
    },
    itemIsSelected (item) {
      this.wantToSelect = !this.wantToSelect
      this.status = item
      this.$emit('selectedValue', item)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .ipt {
    background: $team_gray_popup;
  }
  .listes:hover {
    background: rgb(243 244 246);
  }
</style>
