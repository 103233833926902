<template>
  <div class="new-instruction">
    <popup-base>
      <div class="begin bg-white w-3/4 m-auto border">
        <div class="header p-8 flex items-center justify-between">
          <div class="title-bloc">
            <div class="title text-2xl font-medium">{{ $t('newInstructionButton') }}</div>
            <div class="sous-title mt-3">{{ $t('aboutInstruction') }}</div>
          </div>
          <div class="close cursor-pointer" @click="closeAdd">
            <icon :data="icons.close" original width="25" height="25"/>
          </div>
        </div>
        <div class="body p-8">
          <div class="form py-6">
            <div class="login-form">
              <div class="label mb-2">{{ $t('entitled') }}</div>
              <div class="input-bloc">
                <input
                  v-model="name"
                  type="text"
                  class="ipt w-full p-4 border rounded-lg"
                >
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('description') }}</div>
              <div class="input-bloc">
                <textarea
                  v-model="description"
                  rows="4"
                  class="ipt w-full p-4 border rounded-lg resize-none"
                ></textarea>
              </div>
            </div><br>
            <div class="login-form">
              <div class="label mb-2">{{ $t('attachments') }}</div>
              <div class="input-bloc relative">
                <div class="ipt choose w-full py-4 pl-4 pr-12 border border-dashed rounded-lg">
                  <div v-if="file === ''">{{ $t('clickChooseFile') }}</div>
                  <div v-else class="text-black">{{ file.name }}</div>
                </div>
                <div class="absolute flex border border-transparent right-0 top-0 h-full w-12 cursor-pointer" @click="chooseFile">
                  <div class="icon flex items-center justify-center rounded-tl-xl rounded-bl-xl z-10 h-full w-full">
                    <icon :data="icons.more" original width="25" height="25"/>
                  </div>
                </div>
              </div>
            </div><br>
            <div v-if="error" class="error text-center text-red-500 mb-5">{{ $t('errorAddAdmin') }}</div>
          </div>
          <div class="login-button flex items-center justify-end">
            <div class="cancel-button mr-3">
              <button-base
                :label="$t('cancelButton')"
                fontcolor="#909090"
                background="#FFFFFF"
                @click="closeAdd"
              />
            </div>
            <div class="valid-button ml-3">
              <button-base
                :label="$t('continuousButton')"
                :is-loading="loader"
                @click="openConsignee"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '../add/popup-base'
import ButtonBase from '../add/button-base'
import close from '../../../assets/icons/ic_close.svg'
import more from '../../../assets/icons/ic_add_more.svg'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
export default {
  name: 'new-instruction',
  components: { ButtonBase, PopupBase },
  data () {
    return {
      icons: { close, more },
      name: '',
      description: '',
      file: '',
      fileName: '',
      error: false,
      loader: false
    }
  },
  methods: {
    chooseFile () { // SELECT A FILE
      const input = document.createElement('input')
      input.type = 'file'
      input.setAttribute('accept', '.pdf')
      input.onchange = e => {
        this.file = e.target.files[0]
        // this.url = URL.createObjectURL(this.file)
        const reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onload = a => {
          this.fileName = reader.result
        }
      }
      input.click()
    },
    openConsignee () {
      if (this.name === '' || this.description === '') {
        this.error = true
      } else {
        this.error = false
        this.loader = true
        if (this.file !== '') {
          const storage = getStorage()
          const storageRef = ref(storage, 'instruction/file/' + this.file.name)
          const uploadTask = uploadBytesResumable(storageRef, this.file)
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              console.log('Upload is ' + progress + '% done')
            },
            (error) => {
              console.log(error.code)
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                console.log('File available at', downloadURL)
                const data = {
                  title: this.name,
                  description: this.description,
                  file: downloadURL
                }
                this.$emit('getInstructionInformation', data)
              })
            })
        } else {
          const data = {
            title: this.name,
            description: this.description,
            file: ''
          }
          this.$emit('getInstructionInformation', data)
        }
        this.$emit('openConsignee', true)
      }
    },
    closeAdd () {
      this.$emit('closeNewOne', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .begin {
    border-top: 4px solid $team_blue;
  }
  .sous-title {
    color: #909090;
  }
  .header, .ipt {
    background: $team_gray_popup;
  }
  .choose {
    color: #979797;
  }
  .cancel-button::v-deep {
    .button-base {
      .page-button-real {
        border: none;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
  .valid-button::v-deep {
    .button-base {
      .page-button-real {
        border-radius: .25rem;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>
