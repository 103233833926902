<template>
  <div class="new-instruction-time">
    <popup-base>
      <div class="begin bg-white w-3/4 m-auto border">
        <div class="header p-8 flex justify-between">
          <div class="title-bloc">
            <div class="title text-2xl font-medium">{{ $t('newInstructionButton') }}</div>
            <div class="sous-title mt-3">{{ $t('sentTime') }}</div>
          </div>
          <div class="close cursor-pointer" @click="closeAdd">
            <icon :data="icons.close" original width="25" height="25"/>
          </div>
        </div>
        <div class="body p-8">
          <div class="form py-6">
            <div class="login-form">
              <div class="radio-bloc flex items-center" @click="changePosition(1)">
                <div class="radios cursor-pointer">
                  <icon v-if="position === 1" :data="icons.radioSelected" original width="20" height="20"/>
                  <icon v-else :data="icons.radio" original width="20" height="20"/>
                </div>
                <div class="radio-text ml-6 cursor-pointer">{{ $t('sendNow') }}</div>
              </div>
            </div><br>
            <div class="login-form">
              <div class="radio-bloc flex w-full" @click="changePosition(2)">
                <div class="radios cursor-pointer">
                  <icon v-if="position === 2" :data="icons.radioSelected" original width="20" height="20"/>
                  <icon v-else :data="icons.radio" original width="20" height="20"/>
                </div>
                <div class="radio-second ml-6 w-full">
                  <div class="radio-text cursor-pointer">{{ $t('sendLater') }}</div>
                  <div class="radio-input w-full mt-4">
                    <v-date-picker class="inline-block birth h-full w-full z-50" :min-date="new Date()" v-model="time" mode="datetime" is24hr>
                      <template v-slot="{ inputValue, togglePopover }">
                        <div class="flex items-center">
                          <input
                            :value="inputValue"
                            placeholder="12/08/1993"
                            readonly
                            class="ipt w-full p-4 appearance-none border border-r-0 rounded-l-lg focus:outline-none"
                            @click="togglePopover()"
                          />
                          <button
                            class="ipt p-4 border border-l-0 rounded-r-lg focus:outline-none"
                            @click="togglePopover()"
                          >
                            <icon :data="icons.calendar" original width="15" height="15"/>
                          </button>
                        </div>
                      </template>
                    </v-date-picker>
                  </div>
                </div>
              </div>
            </div><br>
          </div>
          <div class="login-button flex items-center justify-end">
            <div class="cancel-button mr-3">
              <button-base
                :label="$t('goBackButton')"
                fontcolor="#909090"
                background="#FFFFFF"
                @click="goBack"
              />
            </div>
            <div class="valid-button ml-3">
              <button-base
                :label="$t('sendButton')"
                :is-loading="loader"
                @click="closeAdd"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '../add/popup-base'
import ButtonBase from '../add/button-base'
import close from '../../../assets/icons/ic_close.svg'
import radio from '../../../assets/icons/ic_radio.svg'
import radioSelected from '../../../assets/icons/ic_radio_select.svg'
import { collection, doc, setDoc } from 'firebase/firestore'
import { firestore } from '../../../main'
import calendar from '../../../assets/icons/ic_calendar.svg'
export default {
  name: 'new-instruction-time',
  components: { ButtonBase, PopupBase },
  props: {
    instructionInformation: Object,
    instructionReceiver: Object
  },
  data () {
    return {
      icons: { close, radio, radioSelected, calendar },
      position: 2,
      time: new Date(),
      loader: false
    }
  },
  methods: {
    changePosition (index) {
      this.position = index
    },
    goBack () {
      this.$emit('goBackConsignee', true)
    },
    async closeAdd () { // SAVE NEW INSTRUCTION
      if (this.position === 1) {
        this.time = new Date().getTime()
      } else {
        this.time = new Date(this.time).getTime()
      }
      this.loader = true
      const newInstruction = doc(collection(firestore, 'instruction'))
      const data = {
        uid: newInstruction.id,
        addedBy: this.$store.getters.userId,
        createdAt: new Date().getTime(),
        instruction: this.instructionInformation,
        receiver: this.instructionReceiver,
        numberAgent: this.instructionReceiver.agents.length + this.instructionReceiver.controllers.length,
        sendAt: new Date(this.time).getTime(),
        isRead: false,
        numberSee: 0
      }
      await setDoc(newInstruction, data)

      for (let i = 0; i < this.instructionReceiver.agents.length; i++) {
        const newInstructions = doc(firestore, 'notifications', this.instructionReceiver.agents[i], 'notifications', newInstruction.id)
        const instructionData = {
          uid: newInstruction.id,
          addedBy: this.$store.getters.userId,
          createdAt: new Date().getTime(),
          sendAt: new Date(this.time).getTime(),
          instruction: this.instructionInformation,
          isRead: false,
          numberSee: 0
        }
        await setDoc(newInstructions, instructionData)
      }
      for (let i = 0; i < this.instructionReceiver.controllers.length; i++) {
        const newInstructions = doc(firestore, 'notifications', this.instructionReceiver.controllers[i], 'notifications', newInstruction.id)
        const instructionData = {
          uid: newInstruction.id,
          addedBy: this.$store.getters.userId,
          createdAt: new Date().getTime(),
          sendAt: new Date(this.time).getTime(),
          instruction: this.instructionInformation,
          isRead: false,
          numberSee: 0
        }
        await setDoc(newInstructions, instructionData)
      }
      setTimeout(() => { this.$emit('closeTime', false) }, 10000)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .begin {
    border-top: 4px solid $team_blue;
  }
  .svg-fill {
    fill: transparent;
  }
  .sous-title {
    color: #909090;
  }
  .header, .ipt {
    background: $team_gray_popup;
  }
  .radio-input::v-deep {
    .vc-popover-content-wrapper {
      top: -16rem !important;
    }
    .vc-container {
      font-family: $font-default !important;
      .vc-time-date[data-v-63f66eaa] {
        display: none !important;
      }
      .vc-day-content {
        font-weight: 400 !important;
      }
    }
  }
  .cancel-button::v-deep {
    .button-base {
      .page-button-real {
        border: none;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
  .valid-button::v-deep {
    .button-base {
      .page-button-real {
        border-radius: .25rem;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>
