<template>
  <div class="filter-by-category">
    <div class="begin">
      <div class="label mb-2">{{ $t('personalCategory') }}</div>
      <div class="input-bloc">
        <Multiselect
          v-model="category"
          :options="options"
          :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded-lg px-4 py-5 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
          @select="selectCategory"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
export default {
  name: 'filter-by-category',
  components: { Multiselect },
  data () {
    return {
      category: '',
      options: ['Agent', 'Superviseur', 'Chef zone', 'Point de contact']
    }
  },
  methods: {
    selectCategory () {
      this.$emit('selectCategory', this.category)
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .ipt {
    background: $team_gray_popup;
  }
</style>
