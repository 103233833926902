const departments = [
  'ALIBORI',
  'ATACORA',
  'ATLANTIQUE',
  'BORGOU',
  'COLLINES',
  'COUFFO',
  'DONGA',
  'LITTORAL',
  'MONO',
  'OUEME',
  'PLATEAU',
  'ZOU'
]
export default departments
