<template>
  <div class="new-instruction-consignee">
    <popup-base>
      <div class="begin bg-white w-3/4 m-auto border">
        <div class="header p-8 flex justify-between">
          <div class="title-bloc">
            <div class="title text-2xl font-medium">{{ $t('newInstructionButton') }}</div>
            <div class="sous-title mt-3">{{ $t('consigneeInstruction') }}</div>
          </div>
          <div class="close cursor-pointer" @click="closeAdd">
            <icon :data="icons.close" original width="25" height="25"/>
          </div>
        </div>
        <div class="body p-8">
          <div class="form py-6">
            <div class="login-form">
              <div class="label mb-2">{{ $t('filterBy') }}</div>
              <div class="input-bloc">
                <Multiselect
                  v-model="filter"
                  :options="optionsFilter"
                  :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded-lg px-4 py-5 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
                  @select="selectFilter"
                />
              </div>
            </div><br>
            <div v-if="showCategory" class="login-form mb-5">
              <filter-by-category @selectCategory="selectCategory"/>
            </div>
            <div v-if="showRegion" class="login-form mb-5">
              <div class="label mb-2">{{ $t('geographicalRegion') }}</div>
              <filter-by-zone
                :status-list="optionsName"
                @selectedValue="selectedValue"
              />
            </div>
            <div v-if="showStatus" class="login-form mb-5">
              <filter-by-status @selectStatus="selectStatus"/>
            </div>
            <div v-if="showAgent" class="login-form mb-5">
              <div class="label mb-2">{{ $t('agentsMenu') }}</div>
              <div class="input-great-bloc border border-dashed rounded-lg p-6">
                <Multiselect
                  v-model="selectedAgents"
                  mode="tags"
                  :options="allAgents"
                  label="name"
                  value-prop="uid"
                  :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded-lg px-4 py-5 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
                  @select="selectedValuesAgent"
                />
                <div class="label-small mb-2 mt-4">{{ $t('exclusion') }}</div>
                <div class="input-bloc">
                  <Multiselect
                    v-model="excludedAgents"
                    mode="tags"
                    :options="agentExclusion"
                    label="name"
                    value-prop="uid"
                    :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded-lg px-4 py-5 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
                    @select="selectedValuesAgentExclusion"
                  />
                </div>
              </div>
            </div>
            <div v-if="showController" class="login-form mb-5">
              <div class="label mb-2">{{ $t('supervisors') }}</div>
              <div class="input-great-bloc border border-dashed rounded-lg p-6">
                <Multiselect
                  v-model="selectedControllers"
                  mode="tags"
                  :options="allControllers"
                  label="name"
                  value-prop="uid"
                  :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded-lg px-4 py-5 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
                  @select="selectedValuesController"
                />
                <div class="label-small mb-2 mt-4">{{ $t('exclusion') }}</div>
                <div class="input-bloc">
                  <Multiselect
                    v-model="excludedControllers"
                    mode="tags"
                    :options="controllerExclusion"
                    label="name"
                    value-prop="uid"
                    :classes="{
                    container: 'ipt relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded-lg px-4 py-5 leading-snug outline-none',
                    clear: 'hidden',
                    optionSelected: 'text-black bg-none',
                    optionSelectedPointed: 'text-gray-800 bg-gray-100'
                  }"
                    @select="selectedValuesControllerExclusion"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="login-button flex items-center justify-end">
            <div class="cancel-button mr-3">
              <button-base
                :label="$t('goBackButton')"
                fontcolor="#909090"
                background="#FFFFFF"
                @click="goBack"
              />
            </div>
            <div class="valid-button ml-3">
              <button-base
                :label="$t('continuousButton')"
                :is-loading="loader"
                @click="goToNext"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </div>
</template>

<script>
import PopupBase from '../add/popup-base'
import close from '../../../assets/icons/ic_close.svg'
import more from '../../../assets/icons/ic_add_more.svg'
import ButtonBase from '../add/button-base'
import Multiselect from '@vueform/multiselect'
import FilterByCategory from './filter-by-category'
import FilterByZone from './filter-by-zone'
import http from '../../../plugins/https'
import FilterByStatus from './filter-by-status'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { firestore } from '../../../main'
import departments from '@/components/helper/add/department-base'
export default {
  name: 'new-instruction-consignee',
  components: { FilterByStatus, FilterByZone, FilterByCategory, Multiselect, ButtonBase, PopupBase },
  data () {
    return {
      icons: { close, more },
      showController: true,
      showAgent: true,
      showRegion: false,
      showCategory: false,
      showStatus: false,
      filter: null,
      optionsFilter: [
        { value: 'personalCategory', label: this.$t('personalCategory') },
        { value: 'region', label: this.$t('geographicalRegion') },
        { value: 'agent', label: this.$t('agentsMenu') },
        { value: 'status', label: this.$t('status') + 's' }
      ],
      agents: [],
      selectedAgents: [],
      agentExclusion: [],
      excludedAgents: [],
      controllers: [],
      selectedControllers: [],
      controllerExclusion: [],
      excludedControllers: [],
      isCategory: '',
      optionsName: departments,
      trieOption: null,
      isStatus: '',
      allAgents: [{ uid: 'all', name: 'Tous les agents' }],
      allControllers: [{ uid: 'all', name: 'Tous les contrôleurs' }],
      loader: false
    }
  },
  created () {
    // this.getAllDepartments()
  },
  computed: {},
  methods: {
    selectFilter () {
      switch (this.filter) {
      case 'personalCategory':
        this.showCategory = true
        this.showRegion = false
        this.showStatus = false
        this.showController = true
        break
      case 'region':
        this.showRegion = true
        this.showCategory = false
        this.showStatus = false
        this.showController = true
        break
      case 'agent':
        this.showAgent = true
        this.showController = false
        this.showRegion = false
        this.showCategory = false
        this.showStatus = false
        this.getAgentStats()
        break
      case 'status':
        this.showStatus = true
        this.showRegion = false
        this.showCategory = false
        this.showController = true
        break
      }
    },
    async getAgentStats () { // GET ALL AGENT NUMBER
      const q = query(collection(firestore, 'agents'), where('agentFunction', '==', 'agent'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allAgents.push(doc.data())
        this.agentExclusion.push(doc.data())
      })
    },
    async getControllerStats () { // GET ALL CONTROLLER NUMBER
      const q = query(collection(firestore, 'agents'), where('agentFunction', '==', 'supervisor'))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allControllers.push(doc.data())
        this.controllerExclusion.push(doc.data())
      })
    },
    async selectedValue (answer) {
      this.trieOption = answer
      const q = query(collection(firestore, 'agents'),
        where('agentFunction', '==', 'Agent'),
        where('department', '==', this.trieOption)
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allAgents.push(doc.data())
        this.agentExclusion.push(doc.data())
      })

      const q1 = query(collection(firestore, 'agents'),
        where('agentFunction', '==', 'Contrôleur'),
        where('department', '==', this.trieOption)
      )
      const querySnapshot1 = await getDocs(q1)
      querySnapshot1.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allControllers.push(doc.data())
        this.controllerExclusion.push(doc.data())
      })
    },
    selectedValuesAgent () {
      for (const i in this.selectedAgents) {
        if (this.selectedAgents[i] === 'all') {
          this.allAgents.splice(i, 1)
          for (let j = 0; j < this.allAgents.length; j++) {
            this.agents.push(this.allAgents[j].uid)
          }
        } else {
          this.agents = this.selectedAgents
        }
      }
      console.log(this.agents)
    },
    selectedValuesAgentExclusion () {
      console.log(this.excludedAgents)
    },
    selectedValuesController () {
      /* for (let j = 0; j < answer.length; j++) {
        if (answer[j] === 'all') {
          this.allControllers.splice(j, 1)
          for (let i = 0; i < this.allControllers.length; i++) {
            this.controllers.push(this.allControllers[i].uid)
          }
        } else {
          this.controllers = answer
        }
      } */

      for (const i in this.selectedControllers) {
        if (this.selectedControllers[i] === 'all') {
          this.allControllers.splice(i, 1)
          for (let j = 0; j < this.allControllers.length; j++) {
            this.controllers.push(this.allControllers[j].uid)
          }
        } else {
          this.controllers = this.selectedControllers
        }
      }
      console.log(this.controllers)
    },
    selectedValuesControllerExclusion () {
      console.log(this.excludedControllers)
    },
    getAllDepartments () { // GET ALL GEOGRAPHICAL ZONE
      http.get('https://bj-decoupage-territorial.herokuapp.com/api/v1/departments')
        .then(response => {
          // console.log(response.departments)
          this.optionsName = response.departments
        }).catch(error => {
          console.log(error)
        })
    },
    async selectStatus (val) { // SELECT STATUS
      this.isStatus = val
      const q = query(collection(firestore, 'agents'),
        where('agentFunction', '==', 'agent'),
        where('status', '==', this.isStatus)
      )
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allAgents.push(doc.data())
      })

      const q1 = query(collection(firestore, 'agents'),
        where('agentFunction', '==', 'supervisor'),
        where('status', '==', this.isStatus)
      )
      const querySnapshot1 = await getDocs(q1)
      querySnapshot1.forEach((doc) => {
        // console.log(doc.id, ' => ', doc.data())
        this.allControllers.push(doc.data())
      })
    },
    selectCategory (val) { // SELECT PERSONAL CATEGORY
      this.isCategory = val
      switch (this.isCategory) {
      case 'Agent':
        this.showAgent = true
        this.showController = false
        this.showRegion = false
        this.showCategory = true
        this.showStatus = false
        this.getAgentStats()
        break
      case 'Superviseur':
        this.showAgent = false
        this.showController = true
        this.showRegion = false
        this.showCategory = true
        this.showStatus = false
        this.getControllerStats()
        break
      case 'Chef zone':
        break
      case 'Point de contact':
        break
      }
    },
    goToNext () { // SEND INSTRUCTION'S RECEIVERS INFORMATION
      this.loader = true
      for (let i = 0; i < this.agents.length; i++) {
        for (let j = 0; j < this.excludedAgents.length; j++) {
          if (this.agents[i] === this.excludedAgents[j]) {
            this.agents.splice(i, 1)
          }
        }
      }
      for (let i = 0; i < this.controllers.length; i++) {
        for (let j = 0; j < this.excludedControllers.length; j++) {
          if (this.controllers[i] === this.excludedControllers[j]) {
            this.controllers.splice(i, 1)
          }
        }
      }
      const data = {
        agents: this.agents,
        controllers: this.controllers
      }
      this.$emit('instructionReceiver', data)
      this.$emit('openTime', true)
    },
    goBack () {
      this.$emit('goBackNewOne', false)
    },
    closeAdd () {
      this.$emit('closeNewOne', false)
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .begin {
    border-top: 4px solid $team_blue;
  }
  .sous-title, .label-small {
    color: #909090;
  }
  .input-great-bloc {
    border-color: #8E8EA9;
  }
  .header, .ipt {
    background: $team_gray_popup;
  }
  .choose {
    color: #979797;
  }
  .input-bloc::v-deep {
    .ipt {
      .multiselect-tags {
        .multiselect-tags-search-wrapper {
          input {
            background: $team_gray_popup !important;
          }
        }
      }
    }
  }
  .cancel-button::v-deep {
    .button-base {
      .page-button-real {
        border: none;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
  .valid-button::v-deep {
    .button-base {
      .page-button-real {
        border-radius: .25rem;
        h4 {
          font-size: 1.25rem;
        }
      }
    }
  }
</style>
