<template>
  <div class="instruction-lists">
    <div class="begin p-8 border rounded-2xl flex mb-6 cursor-pointer">
      <div class="icon-title mr-6">
        <icon :data="icons.book" original width="20" height="20"/>
      </div>
      <div class="content">
        <div class="title font-medium text-xl">{{ instructionsLists.instruction.title }}</div>
        <div class="description leading-relaxed mt-3 w-3/4">{{ instructionsLists.instruction.description }}</div>
        <div class="attach flex items-center mt-2">
          <div class="attach-icon mr-6">
            <icon :data="icons.attach" original width="30" height="30"/>
          </div>
          <div class="attach-name">
            <div v-if="instructionsLists.instruction.file !== ''" class="cursor-pointer" @click.stop="openFile">{{ 'consigne.pdf' }}</div>
            <div v-else>Aucune pièce jointe</div>
          </div>
        </div>
        <div class="bloc-1 flex items-center mt-4">
          <div class="date capitalize mr-8">
            <span>{{ $t('date') + ' : ' }}</span>
            <span>{{ moment(instructionsLists.sendAt) }}</span>
          </div>
          <div class="see flex items-center">
            <div class="see-number">{{ instructionsLists.numberSee }}</div>
            <div class="attach-icon ml-2">
              <icon :data="icons.eye" original width="15" height="15"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import book from '../../../assets/icons/ic_instruction_title.svg'
import attach from '../../../assets/icons/ic_attach.svg'
import eye from '../../../assets/icons/ic_eye.svg'
import * as moment from 'moment'
import 'moment/locale/fr'
export default {
  name: 'instruction-lists',
  props: {
    instructionsLists: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  data () {
    return {
      icons: { book, attach, eye }
    }
  },
  methods: {
    getNotification () {},
    moment: function (date) {
      return moment(date).format('D MMM YYYY')
    },
    openFile () {
      window.open(this.instructionsLists.instruction.file, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/styles/sass/variables";
  .begin {
    background: $team_gray_popup;
  }
  .attach-name {
    color: $team_indigo;
  }
  .bloc-1 {
    color: #909090;
  }
</style>
